@use 'sass:map';@import "@/src/assets/styles/global.scss";
.CodeEditorWrapper {
	display: flex;
	flex-direction: column;
	padding-top: $spacing-5x;
	height: 100%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	overflow: hidden;

	.LanguageTab {
		display: flex;
		justify-content: flex-end;
		padding: $spacing-2x $spacing-4x;
		border-bottom: 1px solid kv-color('neutral-7');
		background-color: kv-color('neutral-8');
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
}
