@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ControlChange {
	position: relative;
	padding-bottom: 31px;

	.Content {
		padding: $spacing-3x;

		.Label {
			color: kv-color('neutral-4');
			height: 36px;

			@include kv-font-label-small-regular;

			.RequestedAt,
			.UserLink {
				@include kv-font-label-small-semibold;
			}
		}
	}

	.Footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		color: kv-color('neutral-2');
		@include kv-font-label-small-regular;

		background-color: kv-color('neutral-6');
		padding: $spacing-2x $spacing-3x;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		min-height: 34px;
		max-height: 34px;
		overflow: hidden;
		transition: min-height 0.2s ease-out;

		.Label {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.ExpandButton {
			@include clickable;

			kv-icon {
				--icon-color: #{kv-color('neutral-2')};
				--icon-width: 20px;
				--icon-height: 20px;
			}

			&:hover kv-icon {
				--icon-color: #{kv-color('neutral-4')};
			}
		}

		.State {
			display: flex;
			align-items: center;
			white-space: pre;

			&::before {
				content: '';
				display: inline-block;
				right: 0;
				top: 0;
				bottom: 0;
				margin-right: $spacing;
				border-radius: 100%;
				width: 10px;
				height: 10px;
				background-color: kv-color('warning', 'dark');
			}

			.LastState {
				text-transform: capitalize;
			}
		}

		.Reason {
			padding-top: $spacing-3x;
			@include ellipsis(2);
		}

		&.Clickable {
			@include clickable;
			@include background-hover(kv-color('neutral-5'));
		}

		&.Expanded {
			min-height: 80px;
		}

		&.Size-large {
			.Reason {
				@include ellipsis(5);
			}

			&.Expanded {
				max-height: 134px;
			}
		}
	}

	&.applied .Footer .State::before {
		background-color: kv-color('success', 'dark');
	}

	&.failed,
	&.rejected {
		.Footer .State::before {
			background-color: kv-color('error', 'dark');
		}
	}
}
