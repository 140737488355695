@use 'sass:map';@import "@/src/assets/styles/global.scss";
.HeaderFilterContainer {
	display: flex;
	align-items: center;
	gap: $spacing-2x;
	cursor: default;
}

.Title {
	@include kv-font-h3-semibold;
}

.DropdownSeparator {
	@include kv-font-label-large-regular;

	color: kv-color('neutral-2');
}

.SelectedItemWrapper {
	@include clickable;

	display: flex;
	align-items: center;
	color: kv-color('neutral-4');

	kv-icon {
		--icon-color: #{kv-color('neutral-4')};
	}

	&:hover,
	&.DropdownOpen {
		color: kv-color('neutral-2');

		kv-icon {
			--icon-color: #{kv-color('neutral-2')};
		}
	}

	&.DropdownDisabled {
		pointer-events: none;
	}
}

.SelectedItem {
	@include kv-font-label-large-regular;
}

.DropdownMenu {
	background-color: kv-color('neutral-7');
	border: 1px solid kv-color('neutral-6');
	border-radius: $spacing;
	white-space: nowrap;
	z-index: 50;
	min-width: 260px;
}

.DropdownLinks {
	padding: $spacing-2x 0 $spacing-2x 0;
	max-height: 400px;
	overflow: auto;

	kv-action-button {
		--button-height-large: 100%;
		--button-padding-x-large: 0;

		&::part(button) {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.OptionWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 $spacing-2x 0 $spacing-4x;

	&:hover {
		background-color: kv-color('neutral-6');
	}

	&Selected {
		background-color: kv-color('neutral-6');

		.OptionDetails {
			.Title {
				@include kv-font-span-regular;
			}
		}
	}

	.OptionDetails {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		@include clickable;

		color: kv-color('neutral-4');

		.Title {
			@include kv-font-span-regular;

			&Selected {
				color: kv-color('neutral-2');

				@include kv-font-span-semibold;
			}
		}

		.Description {
			@include kv-font-label-small-regular;
		}
	}
}

.Option {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: fit-content;

	&:hover {
		background-color: kv-color('neutral-6');
	}

	kv-action-button {
		height: 32px;
	}

	.Action {
		flex: 1;
	}

	.ActionButtonList {
		padding-right: $spacing-2x;
	}
}
