@use 'sass:map';@import "@/src/assets/styles/global.scss";
.WorkloadFormContainer {
	height: 100%;

	.WorkloadSchemaForm {
		--schema-form-x-padding: #{$spacing-6x};
	}
}

.SectionTitleContainer {
	kv-info-label::part(title) {
		margin-bottom: $spacing-2x;
		color: kv-color('neutral-2');

		@include kv-font-span-semibold;
	}
}