@use 'sass:map';@import "@/src/assets/styles/global.scss";
.GuardrailRadioValueField {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: $spacing-4x;
	padding: $spacing-4x;
	border-radius: 2px;
	user-select: none;
	cursor: default;
	transition: border-color 0.2s ease-out;
	background: kv-color('neutral-8');
	border: 1px solid kv-color('neutral-2');
	pointer-events: auto;

	.Field {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: $spacing-2x;

		.Label {
			color: kv-color('neutral-2');
			@include kv-font-h4-semibold;
		}
	}

	&.GuardrailRadioValueFieldDisabled,
	&.GuardrailRadioValueFieldUnchecked {
		border-color: kv-color('neutral-7');

		.Field {
			pointer-events: none;
		}
	}

	&.GuardrailRadioValueFieldUnchecked:not(.GuardrailRadioValueFieldDisabled) {
		cursor: pointer;

		&:hover {
			border-color: kv-color('neutral-2');
		}
	}
}
