@use 'sass:map';@import "@/src/assets/styles/global.scss";
.InclusiveCheckbox {
	display: flex;
	align-items: center;
	gap: $spacing;
	border-radius: 4px;
	background: kv-color('neutral-6');
	height: 36px;
	padding-inline: $spacing-3x;
	cursor: pointer;

	.Checkbox {
		display: flex;
	}

	.Label {
		color: kv-color('neutral-2');
		@include kv-font-span-regular;
	}

	&Disabled {
		background: kv-color('neutral-7');

		.Label {
			color: kv-color('neutral-5');
		}
	}

	&:hover:not(.InclusiveCheckboxDisabled) {
		opacity: 0.8;
	}
}
