@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ConfigurationContainer {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: $spacing-5x;
}

.CardsContainer {
	display: flex;
	flex-direction: column;
	gap: $spacing-4x;
	flex: 1;
	min-height: 0;
	overflow-y: scroll;
	padding-bottom: $spacing-4x;
	margin-right: calc(-1 * $spacing-4x);
	margin-bottom: calc(-1 * $spacing-6x);
}
