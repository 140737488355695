@import '@kelvininc/ui-components/dist/assets/styles/kv-mixins.scss';
@import 'theme.scss';


@mixin clickable() {
	cursor: pointer;
	user-select: none;
	pointer-events: all;
}

@mixin non-clickable() {
	cursor: not-allowed;
	user-select: none;
}

@mixin ellipsis($lines: null) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@if $lines {
		@supports (display: -webkit-box) and (-webkit-line-clamp: $lines) and
			(-webkit-box-orient: vertical) {
			white-space: normal;
			/* stylelint-disable value-no-vendor-prefix */
			display: -webkit-box;
			-webkit-line-clamp: $lines;
			-webkit-box-orient: vertical;
		}
	}
}

@mixin hide-scrollbar() {
	// Standard version (Firefox only for now)
	scrollbar-width: none;

	// For Chrome & Safari
	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin background-opacity($color, $opacity: 0.8) {
	background: $color; /* The Fallback */
	background: rgba($color, $opacity);
}

@mixin background-hover($color, $delay: 0.2s) {
	transition: background $delay ease-out;

	&:hover {
		background: $color;
	}
}

@mixin white-filter() {
	filter: brightness(0) invert(1);
}

@mixin include-colors-variables() {
	--color-neutral-0: #{kv-color('neutral-0')};
	--color-neutral-1: #{kv-color('neutral-1')};
	--color-neutral-2: #{kv-color('neutral-2')};
	--color-neutral-3: #{kv-color('neutral-3')};
	--color-neutral-4: #{kv-color('neutral-4')};
	--color-neutral-5: #{kv-color('neutral-5')};
	--color-neutral-6: #{kv-color('neutral-6')};
	--color-neutral-7: #{kv-color('neutral-7')};
	--color-neutral-8: #{kv-color('neutral-8')};
	--color-neutral-9: #{kv-color('neutral-9')};
	--color-error: #{kv-color('error', 'dark')};
}

