@use 'sass:map';@import "@/src/assets/styles/global.scss";
.WordsCellRenderer {
	display: flex;
	white-space: pre;
}

.Word {
	@include kv-font-span-regular;

	color: kv-color('neutral-2');

	&Visible {
		visibility: visible;
	}

	&Hidden {
		visibility: hidden;
	}
}
