@use 'sass:map';@import "@/src/assets/styles/global.scss";
$action-icon-size: 32px;
$transition-duration: 0.5s;
$back-button-width: 85px;
$title-line-height: 48px;

.Search {
	width: 600px;

	kv-single-select-dropdown {
		--dropdown-max-height: 320px;
		--dropdown-min-height: 136px;
	}
}

.Content {
	height: 100%;
	display: flex;
	flex: 1;
	justify-content: center;
	flex-direction: column;
	padding: 0 96px;
	transition: padding $transition-duration ease-in-out;
}

.Header {
	margin-bottom: calc($title-line-height + $spacing-16x);
	transition: margin-bottom $transition-duration ease-in-out;
	position: relative;

	.Title {
		display: flex;
		color: kv-color('neutral-0');
		@include kv-font-h1-semibold;

		white-space: break-spaces;
		transition-property: font-size, font-weight, line-height;
		transition-duration: $transition-duration;
		transition-timing-function: ease-in-out;
		position: relative;

		.Highlighted {
			color: kv-color('secondary-5');
		}

		> :nth-child(2) {
			position: absolute;
			top: $title-line-height;
			left: 0;
			transition-property: top, left;
			transition-duration: $transition-duration;
			transition-timing-function: ease-in-out;
		}
	}

	.Description {
		color: kv-color('neutral-2');
		@include kv-font-label-medium-regular;

		transition: top $transition-duration ease-in-out;
		position: absolute;
		top: 104px;
	}
}

.AssetsSearch {
	position: absolute;
	z-index: 10;
	top: 0;
	width: 100%;
	transition-property: height, top;
	transition-duration: $transition-duration;
	transition-timing-function: ease-in-out;
	overflow: hidden;
}

.ExpandableButtonCustom.ExpandableButtonCustom {
	position: absolute;
	bottom: calc(($action-icon-size / 2) * -1);
	right: $spacing-6x;
	width: $action-icon-size;
	height: $action-icon-size;
}

.BackButton {
	height: 100%;
	width: $back-button-width;
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid kv-color('neutral-6');

	.Icon {
		@include clickable;

		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	kv-icon {
		--icon-color: #{kv-color('neutral-0')};
		--icon-width: 24px;
		--icon-heigh: 24px;
		--icon-rotation: -180deg;
	}
}

.AssetsSearchContainer {
	display: flex;
	position: relative;
	left: calc($back-button-width * -1);
	width: calc(100% + $back-button-width);
	height: calc(100% - $action-icon-size / 2);
	background: linear-gradient(0deg, kv-color('neutral-7') -45.38%, kv-color('neutral-9') 260.38%);
	border-image-source: linear-gradient(
		90deg,
		kv-color('neutral-6') 0%,
		kv-color('secondary-5') 52.6%,
		kv-color('neutral-6') 100%
	);
	border-image-slice: 1;
	border-bottom-width: 1px;
	border-style: solid;
	transition: left $transition-duration ease-in-out;

	&WithBackButton {
		left: 0;
	}

	&Collapsed {
		.Content {
			padding: 0 $spacing-6x;
		}

		.Header {
			margin-bottom: $spacing-3x;

			.Title {
				@include kv-font-h4-semibold;

				> :nth-child(2) {
					top: 0;
					left: 130px;
				}
			}

			.Description {
				top: 32px;
			}
		}
	}
}
