@use 'sass:map';@import "@/src/assets/styles/global.scss";
$card-radius: $spacing;

.ApplicationScheduleContainer {
	margin-top: $spacing-6x;
	flex: 1;
}

.ApplicationSchedule {
	display: flex;
	flex-direction: column;
	gap: $spacing-4x;
	margin-top: $spacing-6x;
	overflow: auto;
	background-color: kv-color('neutral-9');

	.ScheduleCard {
		display: flex;
		flex-direction: column;
		user-select: none;
		border-color: kv-color('neutral-6');
		background-color: transparent;
		border-radius: $card-radius;

		&.Highlight {
			@include rotate-border-animation($card-radius);
		}

		.ApplicationScheduleCard {
			background-color: kv-color('neutral-8');
			z-index: 1;
			border-radius: $card-radius;
		}
	}
}

.EmptyState.EmptyState {
	justify-content: center;
	flex: 1;

	--illustration-image-width: 100px;
	--illustration-image-height: 86px;
}
