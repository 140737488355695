@use 'sass:map';@import "@/src/assets/styles/global.scss";
.EnableConfigWrapper {
	display: flex;
	gap: $spacing;

	.Description {
		color: kv-color('neutral-2');
		@include kv-font-label-small-regular;
		@include clickable;

		&.Disabled {
			pointer-events: none;
		}
	}
}
