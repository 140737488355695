@use 'sass:map';@import "@/src/assets/styles/global.scss";
.NodeScrollableWrapper {
	height: 100%;
	padding-top: $spacing-5x;
}

.NodeInfoTabContainer {
	height: calc(100% - 45px);
	overflow: auto;

	:global {
		.simplebar-content {
			display: flex;
			flex-direction: column;
		}
	}
}

.NodeInfoTabFooter {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: kv-color('neutral-9');
	width: 100%;
	border-top: 1px solid kv-color('neutral-6');
	padding: $spacing-4x $spacing-6x;
}
