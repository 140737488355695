@use 'sass:map';@import "@/src/assets/styles/global.scss";
$footer-height: 68px;

.DetailValues {
	width: 100%;
	height: calc(100% - $footer-height);
	display: flex;
	flex-direction: column;
	background-color: kv-color('neutral-9');

	.Description {
		@include kv-font-paragraph-regular;

		color: kv-color('neutral-2');
		padding: $spacing-4x $spacing-6x;
		border-bottom: 1px solid kv-color('neutral-6');
		background-color: kv-color('neutral-8');

		.ParameterTitle {
			@include kv-font-span-semibold;
		}
	}

	.ValuesTable {
		display: flex;
		flex: 1;
		padding: $spacing-3x;

		.Table {
			width: 100%;

			--ag-row-hover-color: none;
			--ag-header-cell-hover-background-color: none;
		}
	}
}

.Footer {
	height: $footer-height;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: $spacing-4x;
	border-top: 1px solid kv-color('neutral-6');
}
