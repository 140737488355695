@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ButtonsContainer {
	display: flex;
	justify-content: space-between;
	background-color: $background-color;
	padding: $spacing-4x 0;
	z-index: 0;

	.RightButtons {
		display: flex;
		gap: $spacing-3x;
	}

	&.HasScroll {
		border-top: 1px solid kv-color('neutral-6');
	}
}
