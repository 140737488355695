@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Toast {
	&:not(.FadeOut) {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
	}

	&.FadeOut {
		opacity: 0;
		transform: translateY(20px);
	}
}
