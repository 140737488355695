@use 'sass:map';@import "@/src/assets/styles/global.scss";
$sidepanel-border-width: 1px;

.MultipleSelection {
	display: flex;
	flex-direction: column;

	&Ungrouped {
		gap: 1px;
		padding-bottom: $spacing-4x;
	}

	.ListItem {
		width: calc(var(--data-explorer-sidepanel-width) - $sidepanel-border-width - $spacing-4x);
		padding: $spacing-3x 0 $spacing-5x $spacing-4x;

		&Ungrouped {
			padding-top: 0;
			padding-bottom: 0;
		}

		.Title {
			color: kv-color('neutral-4');
			padding-bottom: $spacing-2x;

			@include kv-font-label-small-uppercase-semibold;
		}
	}

	.ExpandableAssetCard:not(:last-child) {
		border-bottom: 1px solid kv-color('neutral-7');
	}
}

.OptionsContainer {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	gap: 1px;

	&.SemanticTypeGrouping {
		gap: 20px;
	}

	.AssetName {
		color: kv-color('neutral-4');
		@include kv-font-label-small-regular;
	}
}

.IllustrationContainer.IllustrationContainer {
	--illustration-image-width: 75px;
	--illustration-image-height: 64.5px;

	padding-top: 140px;
}
