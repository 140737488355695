@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ConfirmationModal {
	--modal-width: 408px;
	--modal-topbar-height: 56px;

	&::part(topbar) {
		border-bottom: none;
	}
}

.ConfirmationModalContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $spacing-4x;
	height: 100%;
}

.Content {
	margin-top: $spacing-2x;
	padding: 0 $spacing-6x;
}

.Footer {
	display: flex;
	justify-content: flex-end;
	padding: $spacing-4x;
	gap: $spacing-3x;
}
