@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ControlChangeContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Title {
	margin-bottom: 2px;
	@include kv-font-span-regular;

	color: kv-color('neutral-2');
}

.Description {
	display: flex;
	@include kv-font-label-small-regular;

	color: kv-color('neutral-4');

	.Status::before {
		content: '';
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto $spacing-2x;
		width: 1px;
		height: 12px;
		background-color: kv-color('neutral-5');
	}
}

.Change {
	display: flex;
	align-items: center;
	gap: $spacing;

	.Arrow kv-icon {
		--icon-width: 12px;
		--icon-height: 12px;
		--icon-color: #{kv-color('neutral-4')};
	}
}

.Status {
	display: flex;
	align-items: center;

	.TagStatus {
		--tag-status-gap: #{$spacing};
		--tag-status-label-color: #{kv-color('neutral-4')};
	}
}

.ControlChangeCell {
	kv-tooltip {
		--container-white-space: pre-line;
		--container-max-width: 280px;
	}
}
