@use 'sass:map';@import "@/src/assets/styles/global.scss";
.CreateImageContent {
	width: 516px;
	height: 345px;
	display: flex;
	flex-direction: column;
	gap: $spacing-5x;
	padding: $spacing-6x;
	overflow: hidden;

	.Description {
		@include kv-font-paragraph-regular;

		color: kv-color('neutral-2');
	}

	.Tip {
		padding-bottom: 0;
	}

	.ImageStateContainer {
		display: flex;
		gap: $spacing-4x;
		padding: $spacing-4x;
		border: 1px solid kv-color('neutral-6');
		background-color: kv-color('neutral-7');

		.State {
			position: relative;
			height: 24px;
			width: 24px;

			kv-loader {
				--loader-background-color: #{kv-color('neutral-6')};
				--loader-spinner-color: #{kv-color('secondary-5')};
				--loader-padding: #{$spacing-2x};
				--loader-border-size: #{$spacing};
			}

			kv-icon {
				--icon-color: #{kv-color('success')};
			}
		}

		.StateInfoContainer {
			display: flex;
			flex-direction: column;
			gap: $spacing;

			.Title {
				@include kv-font-h4-semibold;

				color: kv-color('neutral-2');
			}

			kv-action-button-text {
				padding-top: $spacing-2x;
			}
		}
	}
}

.CreateImageFooter {
	display: flex;
	justify-content: flex-end;
	padding: $spacing-2x $spacing-4x $spacing-4x;
}
