@use 'sass:map';@import "@/src/assets/styles/global.scss";
$border-width: 2px;

.ValidationSummaryContainer {
	height: 100%;
	margin: $spacing-3x;
	position: relative;

	.RequirementsListContainer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: kv-color('neutral-8');
		transition: all 0.3s ease-in;
		opacity: 1;
		visibility: visible;

		&.ErrorsListReady {
			opacity: 0.97;
		}

		&.HideRequirementsList {
			opacity: 0;
			visibility: hidden;
		}
	}

	.ValidationListContainer {
		overflow: auto;
		padding: $spacing-5x $spacing-8x;
	}

	.ValidationState {
		color: kv-color('neutral-2');
		@include kv-font-h4-semibold;

		padding-bottom: $spacing-5x;
	}

	.ValidationResult {
		display: flex;
		align-items: center;
		padding: $spacing-3x $spacing-4x;
		background-color: kv-color('neutral-7');
		gap: $spacing-2x;

		kv-tag-status {
			--tag-status-icon-size: #{20px};
		}

		kv-action-button-text {
			padding-left: $spacing-3x;
		}
	}

	.ValidationResultMessage {
		color: kv-color('neutral-2');

		@include kv-font-label-small-regular;
	}

	.ErrorPreviewContainer {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.ErrorCount {
		padding-bottom: $spacing-3x;
		color: kv-color('neutral-2');
		transition: opacity 0.3s ease-in;
		opacity: 0;

		@include kv-font-label-small-regular;

		&.DisplayErrors {
			opacity: 1;
		}
	}
}

.DocumentationLink {
	text-decoration: underline;
	@include kv-font-span-semibold;
}
