@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Inputs {
	display: flex;

	.DataInput {
		width: 100%;
		max-width: 456px;
		box-sizing: content-box;
		padding: $spacing-6x $spacing-4x $spacing-8x $spacing-4x;

		&:not(:first-child) {
			border-left: 1px solid kv-color('neutral-6');
		}
	}
}
