@use 'sass:map';@import "@/src/assets/styles/global.scss";
.EmptyStateOverlayContainer {
	display: flex;
	margin-top: 96px;
	width: 100%;

	.Text {
		margin-left: $spacing-4x;
		color: kv-color('neutral-2');

		@include kv-font-span-regular;
	}
}
