@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AssetTypesModal {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.FormContainer {
		display: flex;
		flex-direction: column;
		padding: $spacing-6x $spacing-6x $spacing-12x;
		flex: 1;
		gap: $spacing-5x;
		overflow: auto;
	}

	.FormFooter {
		display: flex;
		justify-content: flex-end;
		padding: $spacing-4x;
		gap: $spacing-3x;
	}
}
