@use 'sass:map';@import "@/src/assets/styles/global.scss";
.MenuContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	overflow: hidden;

	@include clickable;
	@include background-hover(kv-color('neutral-6'));
}

.MenuUserInfo {
	display: flex;
	align-items: center;
}

.MenuContainerDropdownOpen {
	background-color: kv-color('neutral-6');
}

.MenuTextContainer {
	margin-left: $spacing-2x;
	width: 150px;
}
