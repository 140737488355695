@use 'sass:map';@import "@/src/assets/styles/global.scss";
.NavigationContainer {
	position: absolute;
	top: 0;
	height: 100vh;
	background-color: kv-color('neutral-8');
	z-index: 20;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-right: 1px solid kv-color('neutral-6');
	user-select: none;
	will-change: width;
}

.Navigation {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}

.RightResizable:hover,
.RightResizableWhileResizing {
	border-left: 2px solid kv-color('primary');
}

.CustomLogo.CustomLogo {
	padding: 0 $spacing-3x;
	border-bottom: 1px solid kv-color('neutral-6');
}

.UserDropdown {
	background-color: kv-color('neutral-7');
	border-top: 1px solid kv-color('neutral-6');
	padding: $spacing-3x $spacing-2x $spacing-3x $spacing-3x;
	height: 56px;
}
