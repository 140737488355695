@use 'sass:map';@import "@/src/assets/styles/global.scss";
.GuardrailValuesStep {
	display: flex;
	flex-direction: column;
	gap: $spacing-6x;
	height: 100%;
	overflow: scroll;
	border-bottom: 1px solid kv-color('neutral-7');

	.ControlField {
		padding-inline: $spacing-6x;
	}

	.GuardrailValuesForm {
		display: flex;
		flex-direction: column;
		min-height: 0;
	}
}
