@use 'sass:map';@import "@/src/assets/styles/global.scss";
.RecommendationDynacard {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: $spacing-3x;
	padding: $spacing-3x;
	overflow-y: auto;
	background-color: kv-color('neutral-9');
}

.DynacardContainer.DynacardContainer {
	margin: 0;
	padding: 0;
	height: 360px;

	.Title {
		padding: $spacing-2x $spacing-2x 0 $spacing-2x;
		color: kv-color('neutral-4');
		text-align: center;

		@include kv-font-span-regular;
	}
}
