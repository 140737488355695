@use 'sass:map';@import "@/src/assets/styles/global.scss";
.MultiActionsContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.PrimaryActionsContainer {
	display: flex;
	align-items: center;
	gap: $spacing-3x;
}

.SecondaryActionsContainer {
	display: flex;
}
