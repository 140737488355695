@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AssetScheduledParametersModalContainer {
	--modal-height: 613px;
	--modal-width: 863px;
}

.AssetScheduledParametersModal {
	display: flex;
	flex-direction: column;
	height: 100%;

	.Header {
		display: flex;
		justify-content: space-between;
		padding: $spacing-6x $spacing-5x;
		background: kv-color('neutral-8');
		transition: border-color 0.3s ease-out;
		border-bottom: 1px solid kv-color('neutral-8');

		.Details {
			.Label {
				color: kv-color('neutral-4');
				@include kv-font-label-small-uppercase-semibold;
			}

			.Title {
				color: kv-color('neutral-2');
				@include kv-font-paragraph-regular;
			}
		}
	}

	&Skeleton {
		.Body {
			.Skeleton {
				width: 100%;
				height: 395px;
			}
		}

		.Footer {
			.Skeleton {
				width: 70px;
				height: 36px;
			}
		}

		.Header {
			.Details .Skeleton {
				width: 84px;
				height: 28px;
			}

			.Action .Skeleton {
				width: 115px;
				height: 28px;
			}
		}
	}

	&.Scrolling .Header {
		border-color: kv-color('neutral-5');
	}

	.Body {
		flex: 1;
		min-height: 0;
		background: kv-color('neutral-9');
		padding: $spacing-2x;
		overflow: auto;
		gap: $spacing-2x;
		display: flex;
		flex-direction: column;
	}

	.Footer {
		display: flex;
		justify-content: flex-end;
		padding: $spacing-4x;
		background: kv-color('neutral-8');
		border-top: 1px solid kv-color('neutral-9');
	}
}

.Error,
.Empty {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	.Illustration.Illustration {
		justify-content: center;
		flex: 1;

		kv-illustration {
			--illustration-image-width: 100px;
			--illustration-image-height: 86px;
		}
	}
}
