@use 'sass:map';@import "@/src/assets/styles/global.scss";
.RecommendationContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: $spacing-4x;
	width: inherit;
}

.Details {
	flex: 1;
	min-width: 0;
}

.Title {
	margin-bottom: 2px;
	color: kv-color('neutral-2');
	@include kv-font-span-regular;
	@include ellipsis;
}

.Status {
	display: flex;
	align-items: center;

	.TagStatus {
		--tag-status-gap: #{$spacing};
		--tag-status-label-color: #{kv-color('neutral-4')};
	}
}
