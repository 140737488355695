@import '@kelvininc/ui-components/dist/assets/styles/kv-theme.default.scss';

// Shared sizes
$breadcrumb-height: 52px;
$header-height: 54px;
$sidepanel-width: 510px;
$page-min-height: 722px;
$page-min-width: 1020px;
$dropdown-max-height: 300px;

// Table sizes
$table-headers-height: 38px;

// Colors
$background-color: kv-color('neutral-9');
