@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AssetsLayout {
	flex: 1;
	min-height: 0;
	display: flex;
	position: relative;
}

.Container {
	flex: 1;
	min-height: 0;
	min-width: 0;
	display: flex;
	flex-direction: column;
	transition: margin-top 0.5s ease-in-out;
}

.Overlay.Overlay {
	z-index: 5;
}
