@use 'sass:map';@import "@/src/assets/styles/global.scss";
.MenuTextName {
	@include kv-font-label-small-semibold;
	@include ellipsis;
}

.MenuTextEmail {
	margin-top: 2px;

	@include kv-font-label-xsmall-regular;
	@include ellipsis;
}
