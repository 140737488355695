@use 'sass:map';@import "@/src/assets/styles/global.scss";
.TipContainer {
	display: flex;
	align-items: center;
	padding-bottom: $spacing-6x;

	.TipTextContainer {
		margin-left: $spacing-2x;

		kv-icon {
			--icon-color: #{kv-color('neutral-2')};
		}
	}

	.TipTextBold {
		margin-right: $spacing;
		color: kv-color('neutral-2');
		@include kv-font-label-small-semibold;
	}

	.TipText {
		color: kv-color('neutral-4');
		@include kv-font-label-small-regular;
	}
}
