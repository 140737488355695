@use 'sass:map';@import "@/src/assets/styles/global.scss";
.DashboardHeaderContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.Title {
	@include kv-font-h3-semibold;

	color: kv-color('neutral-2');
}

.Actions {
	width: 300px;
}
