@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Dynacard {
	display: block;
	height: 100%;
	width: 100%;

	--primary: #{kv-color('primary')};
	--secondary-5: #{kv-color('secondary-5')};
	--neutral-0: #{kv-color('neutral-0')};
	--neutral-2: #{kv-color('neutral-2')};
	--neutral-4: #{kv-color('neutral-4')};
	--neutral-5: #{kv-color('neutral-5')};
	--neutral-6: #{kv-color('neutral-6')};
	--neutral-7: #{kv-color('neutral-7')};
	--neutral-8: #{kv-color('neutral-8')};
}
