@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Content {
	display: flex;
	flex-direction: column;
	height: 100vh;
	will-change: margin-left;

	.PageContent {
		flex: 1;
		min-height: 0;
	}
}

.ContentNotResizing {
	transition: margin-left 0.3s ease-in-out;
}
