@use 'sass:map';@import "@/src/assets/styles/global.scss";
.TopbarFollow {
	height: 36px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: kv-color('neutral-7');
	padding: 0 $spacing-3x;

	.Text {
		color: kv-color('neutral-2');
		@include kv-font-label-small-regular;
	}

	kv-switch-button::part(button) {
		pointer-events: auto;
	}
}
