@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ConfigurationStep {
	height: 100%;
	border-bottom: 1px solid kv-color('neutral-5');
	overflow: hidden;

	&.EditMode {
		padding-top: $spacing-6x;
	}
}
