@use 'sass:map';@import "@/src/assets/styles/global.scss";
.WizardFormContext {
	height: 100%;

	.StepContainer {
		height: 100%;
	}

	kv-wizard {
		--wizard-stepper-width: 196px;
	}
}
