@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Container {
	border-top: 1px solid kv-color('neutral-6');
	padding: $spacing-2x;
}

.ItemsContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: $spacing;
}
