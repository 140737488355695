@use 'sass:map';@import "@/src/assets/styles/global.scss";
$backgrounds-colors: (
	'primary': kv-color('secondary-4'),
	'secondary': kv-color('neutral-6')
);
$text-colors: (
	'primary': kv-color('neutral-0'),
	'secondary': kv-color('neutral-2')
);
$sizes: (
	'small': 28px,
	'normal': 32px,
	'large': 38px
);
$forms: (
	'rounded': 40px,
	'squared': 2px
);

@mixin avatar-theme($type) {
	$background-color: map.get($backgrounds-colors, $type);
	$text-color: map.get($text-colors, $type);

	background: $background-color;
	color: $text-color;
}

@mixin avatar-size($size) {
	$value: map.get($sizes, $size);

	width: $value;
	height: $value;
}

@mixin avatar-form($form) {
	$border-radius: map.get($forms, $form);

	border-radius: $border-radius;
}

.Avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	flex-shrink: 0;
}

.SizeSmall {
	@include avatar-size('small');
}

.SizeNormal {
	@include avatar-size('normal');
}

.SizeLarge {
	@include avatar-size('large');

	kv-icon {
		--icon-width: 24px;
		--icon-height: 24px;
	}
}

.TypePrimary {
	@include avatar-theme('primary');
}

.TypeSecondary {
	@include avatar-theme('secondary');
}

.FormRounded {
	@include avatar-form('rounded');
}

.FormSquared {
	@include avatar-form('squared');
}

.Letter {
	@include kv-font-h4-semibold;
}
