@use 'sass:map';@import "@/src/assets/styles/global.scss";
.GuardrailValuesForm {
	display: flex;
	flex-direction: column;
	min-height: 0;
}

.Tabs {
	position: sticky;
	top: 0;
	z-index: 1;
	background: kv-color('neutral-8');
	padding-inline: $spacing-6x;
	border-bottom: 1px solid kv-color('neutral-5');

	kv-tab-navigation {
		--tab-list-divider-color: #{kv-color('neutral-8')};
	}
}

.Form {
	padding: $spacing-5x $spacing-6x;
}
