@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Value {
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: $spacing-2x;
}

.Input {
	flex: 1;
}
