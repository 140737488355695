@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Label {
	display: flex;
	align-items: center;
	color: kv-color('neutral-4');
	padding: $spacing;
	white-space: nowrap;
	height: 24px;

	@include kv-font-label-xsmall-uppercase-semibold;
	@include clickable;

	kv-icon {
		margin-left: 1px;

		--icon-color: #{kv-color('neutral-4')};
		--icon-rotation: -90deg;
	}

	kv-icon::part(icon) {
		transition: transform 0.5s ease-in-out;
	}

	&Expanded {
		kv-icon {
			--icon-rotation: 0deg;
		}
	}
}
