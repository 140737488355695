@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ConfigurationTabSkeleton {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: $spacing-5x 0;
	gap: $spacing-4x;

	.InfoCardSkeleton {
		height: 230px;
	}

	.ButtonsSkeleton {
		display: flex;
		justify-content: space-between;

		.DeleteButtonSkeleton {
			width: 148px;
			height: 36px;
		}

		.RightButtonsSkeleton {
			display: flex;
			gap: $spacing-3x;

			.ResetDefaultButtonSkeleton {
				width: 165px;
				height: 36px;
			}

			.DiscardChangesButtonSkeleton {
				width: 140px;
				height: 36px;
			}

			.SaveButtonSkeleton {
				width: 65px;
				height: 36px;
			}
		}
	}
}
