@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Buttons {
	display: flex;
	flex-direction: row;
	gap: $spacing-2x;

	.Button {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $spacing-2x;
		padding-inline: $spacing-4x;
		height: 36px;
		border-radius: 4px;
		background: kv-color('neutral-7');
		cursor: pointer;
		user-select: none;
		transition: border-color 0.2s ease-out;
		border: 1px solid kv-color('neutral-7');

		&:not(.ButtonDisabled) {
			&.ButtonChecked,
			&:hover {
				border-color: kv-color('neutral-2');
			}
		}

		&.ButtonDisabled {
			opacity: 0.4;
			pointer-events: none;
		}

		.Label {
			color: kv-color('neutral-2');
			@include kv-font-label-small-semibold;
		}
	}
}
