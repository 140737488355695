@use 'sass:map';@import "@/src/assets/styles/global.scss";
$logo-height: 32px;

.LogoContainer {
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.IllustrationContainer {
	display: flex;
	flex-direction: row;
	gap: $spacing;
	align-items: flex-end;

	.ExtraLabel {
		@include kv-font-label-xsmall-light;
		
		min-width: 90px;
		color: kv-color('neutral-5');
		margin-bottom: 3px;
	}
}

.Logo {
	kv-illustration {
		--illustration-width: 99px;
		--illustration-height: 32px;
	}

	kv-icon {
		--icon-width: 32px;
		--icon-height: 32px;
	}
}
