@use 'sass:map';@import "@/src/assets/styles/global.scss";
.Text {
	@include ellipsis;
}

.ItemActive {
	background-color: kv-color('neutral-6');

	.Text {
		@include kv-font-span-semibold;
	}
}

.ItemText {
	display: flex;
	align-items: center;
	height: 40px;
	gap: $spacing-2x;
	width: 180px;

	@include kv-font-span-regular;

	kv-icon {
		color: kv-color('neutral-0');

		--icon-width: 20px;
		--icon-height: 20px;
	}
}

.Item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	border: 2px solid transparent;
	border-radius: $spacing;
	padding: $spacing-2x;
	gap: $spacing-2x;

	@include background-hover(kv-color('neutral-6'));

	.RightIcon {
		kv-icon {
			--icon-color: #{kv-color('neutral-5')};
		}
	}

	&:hover {
		text-decoration: none;
	}
}
