@use 'sass:map';@import "@/src/assets/styles/global.scss";
.DateTimeContainer {
	display: flex;
	flex-direction: column;
}

.Date,
.Time {
	display: flex;
	align-items: center;
	text-transform: none;

	&:not(:last-child) {
		margin-bottom: $spacing;
	}
}

.DateIcon,
.TimeIcon {
	margin-right: $spacing;
	color: kv-color('neutral-2');

	kv-icon {
		--icon-color: #{kv-color('neutral-4')};
	}
}

.DateText,
.TimeText {
	@include kv-font-label-small-regular;
}
