@use 'sass:map';@import "@/src/assets/styles/global.scss";
.OptionContainer {
	.Checkbox {
		display: flex;
		align-items: center;

		kv-checkbox {
			--checkbox-icon-size: 12px;

			margin-right: $spacing-2x;
		}
	}

	.Description {
		@include kv-font-span-regular;

		margin-left: $spacing-6x;
		color: kv-color('neutral-3');
	}

	.Children {
		display: flex;
		flex-direction: column;
		gap: 1px;
		padding-top: $spacing-2x;
	}
}

.Group {
	display: flex;
	align-items: center;

	@include clickable;

	&.NotSelectable {
		@include non-clickable;
	}

	.CheckboxLabel {
		@include kv-font-label-xsmall-uppercase-semibold;

		color: kv-color('neutral-4');

		&.Selected {
			color: kv-color('neutral-2');
		}
	}

	&::after {
		content: '';
		display: flex;
		flex: 1;
		height: 1px;
		background: kv-color('neutral-6');
		margin-left: $spacing-2x;
	}
}

.Option {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: $spacing-6x;
	background-color: kv-color('neutral-7');
	height: 60px;

	@include clickable;

	&:hover,
	&.Selected {
		background-color: kv-color('neutral-6');
	}

	.CheckboxLabel {
		@include kv-font-span-regular;

		color: kv-color('neutral-2');

		&.Selected {
			@include kv-font-span-semibold;
		}
	}
}
