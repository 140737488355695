@use 'sass:map';@import "@/src/assets/styles/global.scss";
.UserMessage {
	display: flex;
	gap: $spacing-2x;

	.Avatar {
		background-color: kv-color('secondary-5');
	}
}

.MessageSection {
	display: flex;
	flex-direction: column;
	gap: $spacing-2x;
}

.NameAndTime {
	display: flex;
	flex-direction: column;
}

.DisplayName {
	@include kv-font-label-small-regular;

	color: kv-color('neutral-2');
}

.Timestamp {
	@include kv-font-label-xsmall-regular;

	color: kv-color('neutral-5');
}

.Content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Body {
	align-items: self-start;

	p {
		width: 100%;
		margin: $spacing 0;
		padding: $spacing-2x;
		border-radius: 4px;
		background-color: kv-color('neutral-7');
	}

	display: flex;
	justify-content: space-between;
}

.Action {
	margin-left: $spacing-2x;
	min-width: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	top: $spacing;
}

.Actions {
	padding-right: $spacing-2x;
	text-align: right;
}

.ActionButtons {
	> kv-action-button-text:not(:last-child) {
		margin-right: $spacing;
	}
}

.ReplyAction {
	cursor: pointer;
	color: kv-color('neutral-4');
	@include kv-font-label-small-regular;

	&:hover {
		color: kv-color('neutral-3');
	}
}

.TextareaContainer {
	flex: 1 1 auto;
}

.Footer {
	padding-top: $spacing;
}
