@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ToastContent {
	display: flex;
	gap: $spacing-2x;
	background-color: kv-color('neutral-8');
	padding: $spacing-2x $spacing-4x;
	border-radius: 4px;
	box-shadow: 0 4px 6px rgb(0 0 0 / 10%);

	.SuccessIcon::part(icon) {
		--icon-color: #{kv-color('success')};
	}

	.ErrorIcon::part(icon) {
		position: relative;
		top: $spacing;

		--icon-color: #{kv-color('error', 'dark')};
	}
}
