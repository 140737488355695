@use 'sass:map';@import "@/src/assets/styles/global.scss";
.EditorContent {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.CodeEditor {
		min-height: 0;
		flex: 1;
	}
}
