@use 'sass:map';@import "@/src/assets/styles/global.scss";
$controls-width: 327px;
$controls-height: 36px;

.SelectSkeleton {
	display: flex;
	height: 100%;
	flex-direction: column;

	.Header {
		.SearchSkeletonContainer {
			padding: $spacing-4x;
		}

		.GroupingOptionsSkeleton {
			padding: $spacing-4x;
			border-top: 1px solid kv-color('neutral-7');

			.DatastreamTypesRadioGroupSkeleton {
				display: flex;
				flex-direction: row;
				gap: $spacing;
				margin-top: $spacing-2x;

				.AllSkeleton {
					width: 40px;
					height: $controls-height;
				}

				.MeasurementSkeleton {
					width: 98px;
					height: $controls-height;
				}

				.ComputedSkeleton {
					width: 82px;
					height: $controls-height;
				}
			}
		}
	}

	.ControlSkeleton {
		width: $controls-width;
		height: $controls-height;
	}

	.DatastreamsListSkeleton {
		display: flex;
		flex-direction: column;
		gap: $spacing;
		height: 100%;
		padding: 0 $spacing-4x;

		.LoadingItem {
			width: 100%;
			height: 60px;
		}
	}
}
