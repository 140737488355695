@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ValidationItems {
	display: flex;
	flex-direction: column;
	gap: $spacing-10x;
}

.ItemWrapper {
	display: flex;
	gap: $spacing-4x;

	kv-tag-status {
		margin-top: $spacing-2x;

		--tag-status-icon-size: 24px;
	}
}

.ValidationState {
	position: relative;
	height: 24px;
	width: 24px;
	margin-top: $spacing-2x;

	kv-loader {
		--loader-background-color: #{kv-color('neutral-6')};
		--loader-spinner-color: #{kv-color('secondary-5')};
		--loader-padding: #{$spacing-2x};
		--loader-border-size: #{$spacing};
	}

	&.Pending {
		kv-loader {
			--loader-spinner-color: #{kv-color('neutral-6')};
		}
	}
}

.Description {
	&Inactive {
		--title-color: #{kv-color('neutral-5')};
		--description-color: #{kv-color('neutral-5')};
	}
}

.Requirement {
	max-width: 466px;

	.Title {
		@include kv-font-h4-semibold;

		color: kv-color('neutral-2');

		&Inactive {
			color: kv-color('neutral-5');
		}
	}

	.Description {
		@include kv-font-paragraph-regular;

		color: kv-color('neutral-4');

		&Inactive {
			color: kv-color('neutral-5');
		}
	}
}
