@use 'sass:map';@import "@/src/assets/styles/global.scss";
.NodeNetworkInfoTable {
	flex: 1;
	min-height: 96px;

	:global(.ag-layout-normal) {
		height: 100%;
	}
}

.CustomInfoCard.CustomInfoCard {
	margin-bottom: $spacing-4x;
}
