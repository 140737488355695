@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ScheduleDateTime {
	display: flex;
	align-items: center;

	kv-icon {
		margin-right: $spacing;

		--icon-color: #{kv-color('neutral-2')};
		--icon-width: 16px;
		--icon-height: 16px;
	}

	.DateTime {
		color: kv-color('neutral-2');
		@include kv-font-span-regular;
		@include ellipsis;
	}

	kv-tooltip {
		flex: 1;
		min-width: 0;
	}
}
