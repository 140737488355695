@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ScheduleParameters {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: $spacing-4x;
	width: inherit;
}

.Details {
	flex: 1;
	min-width: 0;
	display: flex;
	flex-direction: column;
	gap: $spacing;

	.Date {
		max-width: 100%;
	}

	.Parameters {
		color: kv-color('neutral-4');
		@include kv-font-label-small-regular;
		@include ellipsis;
	}
}
