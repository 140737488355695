@use 'sass:map';@import "@/src/assets/styles/global.scss";
.FooterContainer {
	display: flex;
	border-top: 1px solid kv-color('neutral-6');
	width: 100%;
	padding: $spacing-2x $spacing-3x $spacing-2x $spacing-2x;
	align-items: center;
	color: kv-color('neutral-4');

	@include kv-font-label-small-regular;
}

.FooterTextDocumentation {
	&:hover {
		text-decoration-line: underline;
	}

	@include clickable;
}

.FooterSeparator {
	border-radius: 100%;
	background-color: kv-color('neutral-4');
	width: $spacing;
	height: $spacing;
	margin-right: $spacing-2x;
	margin-left: $spacing-2x;
	flex-shrink: 0;
}
