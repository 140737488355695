@use 'sass:map';@import "@/src/assets/styles/global.scss";
.CardContainer {
	padding-bottom: $spacing-5x;
}

.NodeConditionsTable {
	flex: 1;
	min-height: 96px;

	:global(.ag-layout-normal) {
		height: 100%;
	}
}
