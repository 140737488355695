@use 'sass:map';@import "@/src/assets/styles/global.scss";
.IllustrationMessageContainer {
	--illustration-image-width: 260px;
	--illustration-image-height: 200px;

	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding-bottom: $spacing-6x;

	kv-illustration {
		--illustration-width: var(--illustration-image-width);
		--illustration-height: var(--illustration-image-height);
	}

	.Title {
		padding-top: $spacing-3x;
		color: kv-color('neutral-2');

		@include kv-font-h4-semibold;
	}

	.Description {
		padding-top: $spacing;
		color: kv-color('neutral-4');

		@include kv-font-label-small-regular;
	}
}
