@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AssetsTypeTable {
	display: flex;
	height: 100%;
	padding-top: $spacing;
}

.AssetTypeModal {
	--modal-width: 516px;
	--modal-height: 400px;
}
