@use 'sass:map';@import "@/src/assets/styles/global.scss";
.RecommendationModalComments {
	display: flex;
	flex-direction: column;
	height: 100%;
}

$comments-header-height: 54px;

.CommentsHeader {
	border-bottom: 1px solid kv-color('neutral-7');
	padding: $spacing-4x;
	height: $comments-header-height;

	.Skeleton {
		width: 70px;
		height: 21px;
	}
}

.ThreadSection {
	flex: 1 1 auto;
	height: 100%;
	height: calc(100% - $comments-header-height);
}
