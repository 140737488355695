@use 'sass:map';@import "@/src/assets/styles/global.scss";
$logo-container-height: 64px;
$button-height: 24px;
$button-width: 24px;
$button-padding: $spacing-4x;
$button-container-padding: $spacing-4x;
$button-container-height: calc($button-height + $button-container-padding * 2);
$button-adjustment: 4px;

.Button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $button-width;
	height: $button-height;
	background-color: kv-color('primary');
	border-radius: 100%;
	@include clickable;

	kv-icon {
		--icon-height: 20px;
		--icon-width: 20px;
	}

	@include background-hover(kv-color('neutral-5'));
}

.ButtonContainer {
	padding: $button-container-padding;
	opacity: 0;
	transition: opacity 0.15s ease-in-out;
}

.FixedButton {
	z-index: 30;
	position: absolute;
	top: calc($logo-container-height - $button-container-height / 2);
	right: -$button-height - $button-adjustment;

	&Show .ButtonContainer,
	&:hover .ButtonContainer {
		opacity: 1;
	}
}
