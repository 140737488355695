@use 'sass:map';@import "@/src/assets/styles/global.scss";
.BasicGuardrailValuesForm {
	display: flex;
	flex-direction: column;
	gap: $spacing-6x;

	.Field {
		kv-form-label {
			margin-bottom: $spacing-2x;
		}
	}

	.Label {
		color: kv-color('neutral-0');
		margin-bottom: $spacing-3x;
		@include kv-font-span-semibold;

		.Required {
			color: kv-color('error', 'dark');
			@include kv-font-span-semibold;

			margin-right: 2px;
		}
	}

	.Options {
		display: flex;
		flex-direction: column;
		gap: $spacing-2x;
	}
}
