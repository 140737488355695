@use 'sass:map';@import "@/src/assets/styles/global.scss";
$icon-size: 24px;

.Tooltip {
	--container-max-width: 280px;
}

.Card {
	background-color: kv-color('neutral-8');
	border-radius: 4px;
	position: relative;
	border: 1px solid kv-color('neutral-7');

	.StatusIcon {
		position: absolute;
		top: calc(-1 * $icon-size / 2);
		left: calc(50% - $icon-size / 2);
		right: auto;

		kv-tag-status::part(icon) {
			--icon-width: #{$icon-size};
			--icon-height: #{$icon-size};
			--icon-background-color: #{kv-color('neutral-0')};
		}
	}

	&.CardSuccess,
	&.CardError,
	&.CardUnknown,
	&.CardWarning {
		margin-top: 10px;
		border-width: 1px;
		border-style: solid;
	}

	&.CardSuccess {
		border-color: kv-color('success');

		.StatusIcon kv-tag-status::part(icon) {
			--icon-color: #{kv-color('success')};
		}
	}

	&.CardError {
		border-color: kv-color('error');

		.StatusIcon kv-tag-status::part(icon) {
			--icon-color: #{kv-color('error')};
		}
	}

	&.CardUnknown {
		border-color: kv-color('neutral-5');

		.StatusIcon kv-tag-status::part(icon) {
			--icon-color: #{kv-color('neutral-5')};
		}
	}

	&.CardWarning {
		border-color: kv-color('warning');

		.StatusIcon kv-tag-status::part(icon) {
			--icon-color: #{kv-color('warning')};
			--icon-background-color: #{kv-color('neutral-8')};
		}
	}
}

.CardHeader {
	padding: $spacing-3x $spacing-4x $spacing-2x $spacing-4x;
}

.CardBody {
	padding: 0 $spacing-4x;
	margin-bottom: $spacing-4x;
	display: flex;
	flex-direction: column;
	gap: $spacing-3x;

	.Info {
		display: flex;
		flex-direction: column;
		gap: $spacing-4x;

		.Header {
			display: flex;
			gap: $spacing-2x;
			align-items: center;
		}

		.Pin {
			&::part(button) {
				--background-color-hover-ghost: #{kv-color('neutral-6')};
				--background-color-focus-ghost: #{kv-color('neutral-6')};
			}

			&::part(icon) {
				--icon-color: #{kv-color('neutral-5')};
			}

			&Active::part(icon) {
				--icon-color: #{kv-color('neutral-2')};
			}
		}

		.Title {
			@include kv-font-h3-semibold;
			@include ellipsis(0);

			color: kv-color('neutral-2');
		}

		.Description {
			@include kv-font-label-small-regular;

			color: kv-color('neutral-4');
		}
	}
}

.CardFooter {
	display: flex;
	gap: 1px;
	background-color: kv-color('neutral-7');
	border-top: 1px solid kv-color('neutral-7');
	border-radius: 0 0 $spacing $spacing;
	overflow: hidden;

	> * {
		padding: $spacing-3x $spacing-4x $spacing-4x $spacing-4x;
		background-color: kv-color('neutral-8');
	}
}
