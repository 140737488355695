@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ScheduleParameterTable {
	width: 100%;
	display: flex;
	flex-direction: column;

	.InfoAlert {
		width: 100%;
		min-height: 40px;
		display: flex;
		align-items: center;
		background-color: kv-color('neutral-7');
		border-bottom: 1px solid kv-color('neutral-6');

		.CustomLink {
			text-decoration: underline;
		}
	}
	
	.ScheduleParameterRow {
		width: 100%;
		min-height: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		background-color: kv-color('neutral-7');
		
		@include kv-font-span-regular;

		&:not(:last-child) {
			border-bottom: 1px solid kv-color('neutral-6');
		}

		&> * {
			padding: $spacing-2x $spacing-4x;
		}

		&.Header {
			min-height: 36px;
			@include kv-font-label-small-semibold;
		}
	}

	&.HasRevertAction .ScheduleParameterRow {
			grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.ValueColumn {
		height: 100%;
		display: flex;
		justify-content: flex-end;
		gap: $spacing;

		kv-icon {
			height: 20px;
			align-content: center;
		}
	}

	.ExpandableActionRow {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: $spacing;
		padding: $spacing-3x $spacing-4x;
		border-top: 1px solid kv-color('neutral-6');
	}

	.ExpandableAction {
		display: flex;
		justify-content: flex-end;
		gap: $spacing-2x;
		@include clickable;

		.Title {
			color: kv-color('neutral-2');

			@include kv-font-paragraph-regular;
		}

		kv-icon {
			--icon-color: #{kv-color('neutral-0')};
			--icon-height: 16px;
			--icon-width: 16px;

			height: 20px;
			align-content: center;
		}

		&.Disabled {
			pointer-events: none;
			
			.Title {
				color: kv-color('neutral-5');
			}

			kv-icon {
				--icon-color: #{kv-color('neutral-5')};
			}
		}
	}
}

.DetailValuesModal {
	--modal-width: 536px;
	--modal-height: 556px;
}