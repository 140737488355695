@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AssetsTable {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;

	.Header {
		width: 100%;
		height: 70px;
	}

	.Body {
		display: flex;
		flex: 1;

		span {
			height: 100%;
			width: 100%;
		}
	}
}
