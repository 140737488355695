@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ControlChangePayloadContainer {
	--main-value-container-background-color: #{kv-color('neutral-6')};
	--secondary-value-container-background-color: #{kv-color('neutral-7')};
	--card-border-color: #{transparent};

	&.PayloadSourceTo {
		--main-value-container-background-color: #{transparent};
		--secondary-value-container-background-color: #{kv-color('neutral-6')};
		--card-border-color: #{kv-color('secondary-5')};
	}

	position: relative;
	border: 1px solid var(--card-border-color);
	height: 100%;
	border-radius: 8px;
	overflow: hidden;

	.ValueLabel {
		color: kv-color('neutral-4');
		@include kv-font-label-small-bold;
	}

	.ValueContainer {
		display: flex;
		align-items: baseline;
		gap: 2px;
		user-select: none;

		.Unit {
			color: kv-color('neutral-2');

			@include ellipsis;

			// And another typography that designers say they will only use here.
			font-size: 20px;
			font-weight: 100;
			line-height: 20px;
			font-style: normal;
			flex-shrink: 0;
			max-width: 60px;
		}
	}

	.LabelContainer {
		display: flex;
		align-items: center;
		gap: $spacing;
	}

	.Value {
		color: kv-color('neutral-2');

		@include ellipsis;

		font-style: normal;
	}

	.MainValueContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: $spacing-4x;
		background-color: var(--main-value-container-background-color);
		transition: all 0.2s ease-in-out;
		height: 102px;
		width: 186px;

		&.HasSecondaryValue {
			height: 204px;
			justify-content: flex-end;

			.LabelContainer {
				padding-bottom: $spacing-8x;
			}
		}

		&.MaxHeight {
			height: 204px;
		}

		.Value {
			// A typography that designers say they will only use here.
			font-size: 32px;
			font-weight: 600;
			line-height: 36px;
		}
	}

	.SecondaryValueContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		top: 0;
		left: 0;
		height: 86px;
		min-width: 121px;
		width: fit-content;
		max-width: 186px;
		overflow: hidden;
		padding: $spacing-4x;
		background-color: var(--secondary-value-container-background-color);
		border-bottom-right-radius: 8px;
		transition: all 0.2s ease-in-out;

		.ValueContainer {
			.Value {
				// Another typography that designers say they will only use here.
				font-size: 24px;
				font-weight: 600;
				line-height: 20px;
			}

			.Unit {
				// And another typography that designers say they will only use here.
				font-size: 16px;
				font-weight: 100;
				line-height: 16px;
			}
		}
	}
}
