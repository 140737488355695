@use 'sass:map';@import "@/src/assets/styles/global.scss";
.AdvancedGuardrailValuesForm {
	display: flex;
	flex-direction: column;
	gap: $spacing-3x;

	.Label {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: $spacing;
		color: kv-color('neutral-0');
		@include kv-font-span-semibold;

		kv-toggle-tip {
			display: block;
			cursor: pointer;
		}
	}
}
