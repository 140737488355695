@use 'sass:map';@import "@/src/assets/styles/global.scss";
.DeployWorkloadWizardContent {
	height: 100%;
	overflow: hidden;

	kv-wizard {
		width: 100%;
		height: 100%;

		--wizard-stepper-width: 196px;
	}
}

.SkeletonContainer {
	display: flex;
	flex-direction: column;
	padding: $spacing-4x;
	gap: $spacing-2x;

	.SkeletonItem {
		width: 100%;
		height: 34px;
	}
}

.WorkloadInfo,
.AppInfo {
	--modal-width: 536px;
	--modal-height: 650px;
}

.Configuration {
	--modal-width: 1028px;
	--modal-height: 648px;
}

.StepContainer {
	width: 100%;
	height: 100%;
}

.AlertModalDescription {
	@include kv-font-paragraph-regular;

	margin-top: $spacing;
	color: kv-color('neutral-6');
}

.Link.Link {
	text-decoration: underline;
}
