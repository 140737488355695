@use 'sass:map';@import "@/src/assets/styles/global.scss";
.ValidationItemsContainer {
	display: flex;
	flex-direction: column;
	gap: $spacing-5x;
}

.ItemWrapper {
	display: flex;
	gap: $spacing;
	align-items: center;

	kv-tag-status {
		--tag-status-icon-warning-background-color: #{kv-color('neutral-0')};
	}
}

.Title {
	color: kv-color('neutral-2');
	padding-bottom: $spacing;

	@include kv-font-label-xsmall-uppercase-semibold;
}

.Description {
	color: kv-color('neutral-4');

	&Inactive {
		color: kv-color('neutral-5');
	}

	@include kv-font-label-small-regular;
}
