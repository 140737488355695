@use 'sass:map';@import "@/src/assets/styles/global.scss";
.DateTimeContainer{
	display: flex;
	gap: $spacing-2x;
	flex-wrap: wrap;

	kv-tag {
		--tag-background-color: #{kv-color('secondary-5', 'base', 0.2)};
		
		&.Irreversible {
			--tag-background-color: #{kv-color('secondary-2', 'base', 0.2)};
		}
	}

	.Separator {
		width: 1px;
		background-color: kv-color('neutral-5');
	}
}