@use 'sass:map';@import "@/src/assets/styles/global.scss";
$tab-navbar-height: 36px;

.Comments {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	&Skeleton {
		justify-content: flex-end;
	}
}

.Topbar {
	height: $tab-navbar-height;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: kv-color('neutral-7');
	padding: 0 $spacing-3x;
}

.List {
	flex: 1;
	display: flex;
	flex-direction: column-reverse;
	overflow: auto;
	min-height: 0;
	padding: $spacing-4x 0;
}

.MessageContainer {
	display: flex;
	flex-direction: column;
	outline: none;
	padding: $spacing-4x $spacing-2x;

	& > * {
		padding-bottom: $spacing-8x;

		&:last-child {
			padding-bottom: 0;
		}
	}
}

.ToastContainer {
	display: flex;
	justify-content: center;
}

.ThreadToast {
	position: absolute;
	top: 60px;
	z-index: 10;
}

.CommentsList {
	display: flex;
	flex-direction: column;

	.Comment {
		display: flex;
		margin-top: $spacing-8x;
		padding: 0 $spacing-2x;
	}

	.ReactLoadingSkeleton {
		display: flex;
	}

	.Circle {
		min-width: 28px;
		height: 28px;
		margin-right: $spacing-2x;
		padding-top: $spacing;

		span {
			width: 28px;
			height: 28px;
		}
	}

	.Row {
		width: 100%;
	}

	.Row .RowName span {
		width: 70px;
	}

	.Row .RowDescription span {
		width: 215px;
		margin-bottom: $spacing-2x;
	}

	.Row .RowText span {
		width: 142px;
		height: 34px;
		border-radius: 6px;
	}
}

.CommentsCreator {
	padding: $spacing-4x $spacing-2x $spacing-2x $spacing-2x;

	.Skeleton {
		width: 100%;
		height: 42px;
		border-radius: $spacing;
	}
}
